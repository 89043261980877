<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Regression"
            right-nav="home"
        />
        <h4 class="my-3 text-center">
            Select the parameter to analyze:
        </h4>
        <b-list-group
            class="bg-white btn-group-toggle btn-group-vertical"
            data-toggle="buttons"
        >
            <label
                v-for="(picker, key) in pickers"
                :key="key"
                class="btn btn-outline-secondary p-3"
                :class="picker_id === key ? ' active': ''"
            >
                <input
                    :id="'picker_' + key"
                    v-model="picker_id"
                    type="radio"
                    class="btn-group-toggle"
                    name="picker_id"
                    :value="key"
                >
                {{ picker }}
            </label>
        </b-list-group>
        <div class="route-btn-view d-flex py-5 h-auto">
            <button
                class="route-btn lg"
                :disabled="disableCI"
                @click="onPressCI"
            >
                Confidence Interval
            </button>
            <button
                class="route-btn lg"
                :disabled="disableHT"
                @click="onPressHT"
            >
                Hypothesis Test
            </button>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import rData from '@/js/regression/regression-data.js'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'More',
    components: {
        TopNavigationBar,
    },
    data () {
        return {
            allData: null,
            pickers: {
                correlation: 'Correlation Coefficent (R)',
                slope: 'Slope (M)',
                dependent: 'Dependent Variable (Y)'
            },
            picker_id: 'correlation',
            disableCI: true,
            disableHT: false
        }
    },
    beforeMount () {
        this.allData = localStore.getStore('regression-data')
    },
    updated () {
        this.show()
    },
    methods: {
        show () {
            if (this.picker_id === 'correlation') {
                this.disableCI = true
                this.disableHT = false
            }
            if (this.picker_id === 'slope') {
                this.disableCI = false
                this.disableHT = false
            }
            if (this.picker_id === 'dependent') {
                this.disableCI = false
                this.disableHT = true
            }
        },
        onPressCI () {
            if (this.picker_id === 'slope') {
                this.$router.push({ name: 'regression/mci' }) // Slope - Confidence Interval
            } else if (this.picker_id === 'dependent') {
                this.$router.push({ name: 'regression/yci' }) // Dependent Variable - Confidence Interval
            }
        },
        onPressHT () {
            var { allData } = this
            const tR = rData.calculateTforR(allData)
            const r = rData.calculateR(allData.items)
            const tM = rData.calculateTforM(allData)
            _.set(allData, 'dataHT', { tR, r, tM })
            localStore.setStoreItem('regression-data', allData)
            if (this.picker_id === 'correlation') {
                this.$router.push({ name: 'regression/rht' }) // Correlation Coefficent - Hypothesis Test
            } else if (this.picker_id === 'slope') {
                this.$router.push({ name: 'regression/mht' }) // Slope - Hypothesis Test
            }
        }
    },

}
</script>
